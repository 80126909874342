import { useSession } from "next-auth/react";

import { HorizontalContainer } from "../layout/horizontal-container";
import { Link } from "../base/link";
import { Text } from "./text";
import { VerticalSpacer } from "../layout/vertical-spacer";
import { usePathname, useSearchParams } from "next/navigation";
import { ROOT_URL, paths } from "@/utils/paths-utils";
import { setQueryStringValues } from "@/utils/query-string-utils";

export const LogInOrRegisterLinks = () => {
  const session = useSession();

  if (session.status === "authenticated") {
    return <></>;
  }

  return (
    <HorizontalContainer>
      <RegisterLink />

      <VerticalSpacer size="x-small" />

      <LoginLink />
    </HorizontalContainer>
  );
};

export const RegisterLink = () => {
  return (
    <Link href={paths.bliMedlem}>
      <Text size="small" style="italic" underline value="Bli medlem" />
    </Link>
  );
};

interface LoginLinkProps {
  notItalic?: boolean;
}

export const LoginLink = ({ notItalic }: LoginLinkProps) => {
  const session = useSession();
  const path = usePathname();
  const searchParams = useSearchParams();
  const encodedQueryString = encodeURIComponent(setQueryStringValues([], searchParams));
  const hasQueryString = encodedQueryString !== "";
  const currentUrl = `${ROOT_URL}${path}${hasQueryString ? "?" : ""}${encodedQueryString}`;

  if (session.status === "authenticated") {
    return <></>;
  }

  return (
    <Link href={`/logg-inn?callbackUrl=${currentUrl}`}>
      <Text size="small" style={notItalic ? undefined : "italic"} underline value="Logg inn" />
    </Link>
  );
};
